#contact{
  flex-direction: column;
  justify-content:initial;

  .home-section-text{
    overflow-x: hidden;
    margin-right: 0;
    padding-right: 0;
    margin-left: 0;
  }
}

.contact-us-form{
  width: 100%;
  margin-right: 8%;
  padding-right: 2%;
  margin-left: 0%;
  text-align: center;

  input{
    margin-top: 15px;
  }
  textarea{
    margin-top:15px;
    margin-bottom:15px;
  }
  .submit{
    text-align:left;
  }
  #SubmitBtn{
    padding: 15px 50px;
    margin-top: -70px;
    background:#007F8E;
  }
  .captcha{
    display:inline-block;
  }
}

.contact-form-error, .contact-form-success{
  display:none;
  color:$clr_white;
  font-size:34px;
  text-align: center;
  border-bottom: 1px solid white;
}
.retry-info {
  font-style:italic;
}

.contact-form-success {
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  width:100%;
  background: rgba(33,33,33,.8);
  z-index: 1008;
  display: none;

  vertical-align: middle;
  text-align: center;

  padding-top:20%;

  .success-info {
    background: white;
    width:40%;
    height:40%;
    color:black!important;

    display: inline-block;

    padding-top:5%;

    img {
    }
  }
}
@media screen and (max-width:1024px){
  #contact .sub-section-center {
    margin-bottom:5px;
  }
  .contact-us-form .row #SubmitBtn {
    margin-top:30px;
  }
}
