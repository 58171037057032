.news-menu-item, .teams-menu-item {
 // margin-bottom:40px;


  p {
    cursor: pointer;
  }
}
.nav-back-arrow-news {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  a, img {
    height: 50%;
    cursor: pointer;
  }
}

.news-text {
  text-align: start;
}
