.team-menu-item {
  width:100%;
  p {
    cursor: pointer;
  }
  h3 {
    //cursor: pointer;
  }
  a {
    color:$clr_neon_green;
    text-decoration: underline;
    font-size: 1em;
  }
  span {
    color:$clr_neon_green;
    text-decoration: underline;
    font-size: 1em;
  }
}
.sub-section-center-leaders {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  height:65%;
}
.team-profile-item {
  display:flex;
  flex-direction: column;

  width:25%;

  margin:20px;
  text-align: left;
  cursor: pointer;
  border-bottom: 2px solid white;
  padding-bottom: 25px;

  img {
    width:100%;
  }
  h6 {
    cursor: pointer;
  }
  span {
    display: block;
    font-size : 0.8em;
  }
}
.sub-section-left {
  .nav-back-arrow {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    a, img {
      height: 21%;
      margin-bottom: 30px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .team-menu-item {
    width:100%;
    margin-bottom:5vh;

    h3 {
      font-size:25px;
    }
    span {
      font-size:.8em;
    }
  }

  .sub-section-center-leaders {
    justify-content: center;
  }
}

.sub-section-center {
  align-items: flex-start;
}

.floatImages {
  display: flex;
  align-items: center;
  margin-bottom: 2em;

  p {
    margin-inline: auto;
    text-align: center;
    padding-inline: 0.1em;
    font-size: 0.8em;
  }

  img {
    cursor: pointer;
    height: 140px;
    width: 140px;
  }
}

.image-size-profile {
  img {
    height: 140px;
    width: 140px;
    padding-bottom: 1em;
    display: block;
    align-items: center;
  }
  p {
    font-size: .8em;
    align-items: center;
    //text-align: justify;
  }
}

  .profile-wrapper {
    width: 130px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}
  figcaption {
    cursor: pointer;
    font-size: 0.9em;
  }

