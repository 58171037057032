.home-logo{
  margin-left:10%;

  h3{
    color:$clr_white;
    text-transform: none;
  }
}
.home-section{
  display:flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top:20vh;
  margin-right:10vw;
  margin-left:10vw;
  margin-bottom:5vh;
  background-color:transparent;
  height:65vh;
  width:80vw;

  .home-section-text{
    z-index:1;
    width:100%;
    margin-right:8%;
    padding-right:2%;
    margin-left:10%;
    padding:1rem;


    text-align: center;

    overflow-y: auto;


    .section-header{
      margin-bottom: 2em;
    }
    p{
      font-size:$fs_normal_text;

      a:hover {
        color:$clr_neon_green;
      }
    }
  }

  .home-section-text::-webkit-scrollbar-track {
    padding: 2px 0;
    background-color: $clr_teal;
    border-radius: 20px;

  }
  .home-section-text::-webkit-scrollbar {
    width: 20px;
  }
  .home-section-text::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,.2);
    padding: 2px 0;
    border-radius: 20px;
    border: 20px outset $clr_neon_green;
  }
  .home-section-text::-webkit-scrollbar-corner {
    background: rgba(0,0,0,0);
  }

}
.home-nav-pips {
  margin-right:25vw;
  margin-left:25vw;
  height:10vh;
  width:50vw;

  display: flex;
  flex-direction: row;
  justify-content: space-around;

  justify-items: flex-start;
  align-items: flex-start;

  .home-nav-pip {
    cursor: pointer;
    text-align: center;
    border-radius: 100%;
    background: $clr_teal;
    height: 30%;
    aspect-ratio: 1/1;

    &:hover {
      background-color: $clr_neon_green;
    }
  }

  .home-nav-pip.active {
    background-color: $clr_neon_green;
  }
}

@media only screen and (max-width: 1260px) {
  .home-logo {
    h3 {
      font-size:40px;
    }
  }
  .home-section{
    display:flex;
    flex-direction: column;
    margin-bottom:40px;

    p{
      font-size: $fs_normal_text;
    }
    .home-section-text{
      max-width: 100vw;
      padding-top:40px;
      padding-bottom:30px;
      padding-right: 30px;
      padding-left: 20px;
    }
  }

  .home-nav-pips {
    align-items: center;
    height:10vh;
    margin-left:10vw;
    width:80vw;
  }
}

@media (max-width: 1024px) and (min-width: 768px) and (max-height: 900px) {
  .home-section {
    margin-top: 30vh !important;
  }
}

@media only screen and (max-width: 1024px) {
  .home-section {
    margin-top: 20vh;
    margin-right: 5vw;
    margin-left: 5vw;
    margin-bottom: 0;
    background-color: transparent;
    height: 75vh;
    width: 90vw;

    .home-section-text{
      padding-right:2%;
      padding-left:0;
      margin-left:0;
      margin-right:0;

      //height:60vh;
      overflow-x:hidden;

      p{
        font-size:0.9em;
      }

      .section-header{
        margin-bottom: 1em;
      }
    }
  }
}
