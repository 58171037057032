#header{
  background-color: transparent;
  position: fixed;
  top: 5vh;
  left: 0;
  right: 0;
  max-width: 100%;
}
.main-navbar{
  height:100%;
  max-width:100%;
  margin-right:5vh;
  margin-left:5vh;

  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  .navbar-nav {
    list-style-type: none;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .navbar-brand {
    width:40%;

    img {
      width:40%;
    }
  }
}

.nav-content{
  width: 100%;
}

.navbar-nav{
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
}

.navbar-nav:first-child{
  margin-top:5vh;
}

.nav-item {
  margin-bottom:30px;
  text-align: right;

  a {
    color:$clr_white;
    font-weight: 700;
    font-size: 20px;
    font-family: $font-stack-secondary;
    text-transform: uppercase;
    border-bottom: 3px solid transparent;

    &:hover{
      border-bottom: 3px solid transparent;
      color: $clr_gray;
    }
  }
  a.active {
    border-bottom: 3px solid $clr_teal;
  }

  .subNav.active {
    display:flex;
    flex-direction: column;
  }
  .subNav {
    display:none;

    a {
      font-size: 18px;
      font-weight:400;

      &:hover{
        border-bottom: 3px solid transparent;
        color: $clr_gray;
      }
    }
    a.active {
      border-bottom: 3px solid transparent;
    }
    a.active::after {
      content:"\A";
      width:10px;
      height:10px;
      border-radius:50%;
      background: $clr_neon_green;
      display:inline-block;
      margin-right:-20px;
      margin-left:10px;
    }
  }
}

/*Mobile Menu*/
/*****************************************************************************************************/
.mobile-nav-menu{
  display:none;
  flex-direction:column;
  background-color: rgba(0, 127, 142, 1);
  border-bottom: 4px solid $clr_teal;

  .mobile-nav-item{
    text-align:center;
    width:100%;

    color:$clr_white;
    font-size: 4vw;
    font-weight: 700;
    font-family: $font-stack-secondary;
    text-transform: uppercase;

    padding-top:20px;
    padding-bottom:20px;

    cursor: pointer;

    &:first-child{
      margin-top:30px;
    }
  }
}
.mobile-close{
  display:none;
}
.mobile-button, .mobile-close{
  height:25px;
  width:25px;
  padding-top:0;

  img{
    width:100%;
    cursor: pointer;
  }
}

@media screen and (max-width:1260px){
  .main-navbar {
    .navbar-nav {
      display: none;
    }
  }
  #header {
    z-index: 999;
  }
}

@media screen and (max-width:1024px){
  #header {
    background-color: unset;
  }
  .main-navbar {
    .navbar-brand {
      width: 50%;

      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width:1200px){
  #header {
    background-color: #007f8e !important;
  }
  
  .main-navbar {
    align-items: center;    
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
  }

  .nav-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-header{
    display: flex;
    flex-direction: column;
  }
}

