
/*Footer*/
/*****************************************************************************************************/
#footer{
  position:relative;
  bottom:0;
  width:100%;

  height:$footer_height;
  margin-top:0;

  max-width:100vw;

  //background-color:$clr_dark_blue;

  font-size: $fs_small_text;
  font-weight: 700;
  color:$clr_white;

  text-align:center;
  display:flex;
  align-items: center;
  justify-content: center;

  .footer-content{
    max-width:100vw;
  }

  a{
    font-size: $fs_small_text;
    font-weight: 700;
    color:$clr_white;
    &:hover{
      color:$clr_gray;
      text-decoration: none;
    }
  }

  .footer-logo {
    width:100%;

    img{
      height: 100px;
    }
  }

  .footer-links{
    margin-top:25px;
    display:flex;
    flex-direction:row;
    justify-content: center;

    .footer-link{
      padding: 0 25px;
    }
  }

  .footer-copy{
    margin-top:30px;
    text-transform: uppercase;

    p {
      font-size: 14px;
      color:$clr_white;
      font-weight:400;
      width:100%;
      margin-bottom:0px;
    }

    a {
      font-size: 14px;
      font-weight:400;
      text-decoration:underline;
      text-transform: uppercase;
    }

  }
}

@media screen and (max-width:1031px){
  #footer{
    height:$footer_mobile_height;

    .footer-content{
      max-width:90vw;
    }

    .footer-links{
      flex-direction:column;
      .footer-link{
        padding: 0;
      }
    }

    .footer-copy {
      p {
        width: 100%;
      }
    }
  }
}
