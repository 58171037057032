#learnMore .home-section-text {
  overflow-x: hidden;
  margin-left: 4%;
}

#pipeline {
  .home-section-text {
    overflow-x: hidden;
    p {
      font-size: $fs_normal_text;
    }
  }
}

@media screen and (max-width: 1024px) {
  #contentMain {
    .home-section-text {
      h5 {
        font-size: 1.2em;
      }
      p {
        font-size: 0.9em;
      }
    }
  }

  #learnMore .home-section-text {
    overflow-x: scroll;
    margin-left: 0;
    .pipeline-section-header h5 {
      text-align: left;
    }
    .pipeline-table {
      width: 48em;
    }
  }

  .pipeline-table-content{
    display:flex;
    flex-direction: column;
  }
  .home-section .pipeline-learn-more-content>div{
    min-width:0px !important;
    font-size: .7em !important;
  }
  .home-section .pipeline-progress .progress-fill {
    margin-top: -20px !important;
}
}

.home-section {
  .pipeline-item-section {
    width: 100%;
    text-align: center;

    .pipeline-item-header {
      padding-bottom: 4em;
    }

    .pipeline-item-header h2 {
      display: inline;
      border-bottom: 2px solid white;
    }
  }

  .pipeline-section-header {
    h5  {
      text-align: center;
      text-transform: uppercase;
      font-size: 4em;
      font-weight: 100;
    }
  }

  .title-divider h3 {
    color: $clr_neon_green;
    font-size: 2em;
    text-align: left;
    text-transform: uppercase;
    border-bottom: 2px solid $clr_neon_green;
  }

  .pipeline-learn-more-content {
    display: flex;
  }

  .pipeline-learn-more-content > div {
    display: flex;
    flex-direction: column;
    flex-basis: 22.5%;
    padding: 1em;
    min-width: 10em;
    text-align: left;
    font-size: 1.3em;
    word-wrap:normal;
  }

  .pipline-text-cell span {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .pipeline-learn-more-content > div + div {
    flex-basis: 35%;
  }

  .pipeline-learn-more-content > div + div + div {
    position: relative;
    flex-basis: 100%;
  }

  .pipeline-progress {
    position: relative;
    width: 100%;
    height: 1.3em;
    margin: 0.6em 0.2em;

    .progress-fill {
      position: absolute;
      width: 20%;
      height: 150%;
      border-radius: 30px;
      background-color: $clr_neon_green;
      margin-top: -37px;
      margin-left: -5px;


    }

    .progress-fill#ap472fill {
      width: 60%;
    }

    .progress-fill#ap612fill {
      width: 33%;
    }
  }

  a {
    color: $clr_neon_green;
    text-decoration: underline;
    font-size: large;
  }

  .graph-line {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background: rgba($clr_teal, 1.5);
  }

  .progress-graph {
    display: flex;

    .progress-chunk {
      position: relative;
      height: 1.5em;
      border-right: 3px solid rgba($clr_teal, 1.5);
      flex-basis: 100%;

      .chunk-label {
        position: absolute;
        width: 100%;
        top: 130%;
        left: 50%;
        font-size: 0.55em;
        font-weight: 100;
        text-align: center;
      }
    }
  }
}



.ap-472-popup{
  margin-top: 2em;
  border-radius: 3px;


  .ap742-img-wrapper{
    width: 100%;
  }

  img{
    max-height: 600px;
    max-width: 100%;
  }
}

.teal-background {
  h2, p{
    color: white;
  }
  background: $clr_teal;
  border-radius: 15px;
}
