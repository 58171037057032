*, html {
  margin:0;
  padding:0;
  box-sizing: border-box;
}
body {
  font-family: $font-stack-primary;
  color: $clr_white;
  font-weight: 400;

  min-height: 100vh;
  background-color: #182434;
}

#bgvid {
  background-color: #182434;
  background: url("/dist/images/video/Background Filter_Teal.png");
}
video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index:-2;
}
#bgFilter {
  background-color: rgba(58, 255, 177, .1);
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index:-1;
}

.body-content{
  display:flex;
  flex-direction: column;

  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

/*** FONTS ******/
p{
  font-family: $font-stack-primary;
  color: $clr_white;
  font-weight: 400;
  font-size:$fs_normal_text;
}
h1{
  color:$clr_white;
  font-weight: 400;
  font-size: $fs_h1;
  font-family: $font-stack-secondary;
}
h2{
  color:$clr_white;
  font-weight: 400;
  font-size: $fs_h2;
  font-family: $font-stack-secondary;
  text-transform: uppercase;
}
h3{
  color:$clr_white;
  font-weight: 700;
  font-size: $fs_h3;
  font-family: $font-stack-secondary;
  padding-bottom: 14px;
  border-bottom: 5px solid $clr_teal;
}
h4{
  color:$clr_white;
  font-weight: 700;
  font-size: $fs_h4;
  font-family: $font-stack-secondary;
  padding-bottom: 14px;
  border-bottom: 2px solid $clr_white;
}
h5{
  color:$clr_white;
  font-weight: 700;
  font-size: $fs_h5;
  font-family: $font-stack-secondary;
  padding-bottom: 14px;
}
h6{
  color:$clr_white;
  font-weight: 400;
  font-size: $fs_h6;
  font-family: $font-stack-secondary;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 2px solid $clr_white;
}

a{
  color: $clr_white;
}

.section-buttons {
  margin-top:40px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.section-button {
  background-color: $clr_teal;
  border: 1px solid $clr_teal;
  border-radius: 25px;
  color: $clr_white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  min-width:210px;
  cursor: pointer;
}
.section-button:hover {
  background-color: $clr_neon_green;
  border: 1px solid $clr_neon_green;
}
.sub-section-center {
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  margin: 2em;
  // height:65%;
}
.sub-section-left {
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 3%;

  height:65%;
  text-align: left;
}

#board, #management, #news1, #news2, #pipeline1, #pipeline2,
#cook, #elfont, #blobaum, #bryson,
#conn, #hardy, #furst, #laden,
#scatina, #slattery, #rook {
  display:none;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


@media only screen and (max-width: 1260px) {
}

@media only screen and (max-width: 1024px) {

  .section-buttons {
    margin-top:30px;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .section-button {
    margin-bottom:30px;
  }

  h2 {
    font-size:40px;
  }
  h4{
    font-size:20px;
  }

  .sub-section-center {
    flex-direction: column;
  }
}
